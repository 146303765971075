@import 'variables';

table.mat-table {
  width: 100%;
  background: none;
  font-family: 'OpenSans', sans-serif;

  .mat-row {
    font-weight: 600;

    &:nth-child(even) {
      background-color: #f9f9fc;
    }

    &:nth-child(odd) {
      background-color: #ffffff;
    }

    .mat-cell {
      border: none;
      color: $color-text-black;
    }
  }

  .mat-header-row {
    .mat-header-cell {
      border: none;
      color: $color-text-black;
      font-size: $font-size-paragraph;
    }
  }
}
