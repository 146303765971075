@import 'variables';

mat-form-field.mat-form-field-appearance-outline {
  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1em) scale(0.75);
    }

    &.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: inherit;
    }
  }

  & .mat-form-field-outline {
    & > * {
      background: $white;
    }
  }

  .mat-form-field-label {
    .mat-form-field-required-marker,
    mat-label {
      color: $grey;
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      .mat-form-field-required-marker,
      mat-label {
        color: $color-primary;
      }
    }
  }

  .mat-input-element:disabled {
    color: $black;
  }

  &.small {
    .mat-form-field-infix {
      padding: 0.5em 0;
    }
  }
  @include supportEdge(width, 180px);
}

// full with helper (autocomplete dropdowns)
.full-width-dropdown {
  width: 100%;
}
