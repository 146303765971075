@import 'variables';

button {
  &.mat-raised-button,
  &.mat-flat-button,
  &.mat-stroked-button,
  &.mat-button {
    min-width: 164px;
    line-height: 40px;

    &:not([disabled]) {
      border-color: inherit;
    }

    &.large {
      line-height: 60px;
    }
  }

  &.color-punch:not([disabled]) {
    &.mat-flat-button {
      background: $color-punch;
      color: $white;
    }

    &.mat-stroked-button {
      color: $color-punch;
    }
  }
}

mat-button-toggle-group {
  mat-button-toggle {
    height: 36px;
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 38px;
      }
    }
  }
  .mat-button-toggle-checked {
    &.mat-button-toggle-appearance-standard {
      color: $white;
      background-color: $color-primary;
    }
  }
}
