$mat-primary-blue: (
  50: #e0effa,
  100: #b3d7f4,
  200: #80bdec,
  300: #4da2e4,
  400: #268edf,
  500: #007ad9,
  600: #0072d5,
  700: #0067cf,
  800: #005dca,
  900: #004ac0,
  A100: #e9f0ff,
  A200: #b6cdff,
  A400: #83aaff,
  A700: #6999ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-secondary-blue: (
  50: #edf8f9,
  100: #d1eff1,
  200: #b2e4e7,
  300: #93d9dd,
  400: #7cd0d6,
  500: #65c8cf,
  600: #5dc2ca,
  700: #53bbc3,
  800: #49b4bd,
  900: #37a7b2,
  A100: #fdffff,
  A200: #cafaff,
  A400: #97f5ff,
  A700: #7ef3ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-error: (
  50: #ffeded,
  100: #ffd2d2,
  200: #ffb5b5,
  300: #ff9797,
  400: #ff8080,
  500: #ff6a6a,
  600: #ff6262,
  700: #ff5757,
  800: #ff4d4d,
  900: #ff3c3c,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe5e5,
  A700: #ffcbcb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// COLORS
$color-primary: map-get($mat-primary-blue, 500);
$color-primary-dark: map-get($mat-primary-blue, 900);
$color-secondary: map-get($mat-secondary-blue, 500);
$color-error: map-get($mat-error, 500);
$color-success: #4fd569;
$color-warning: #ffa351;
$color-punch: #fab348;
$white: #ffffff;
$black: #000000;
$grey: #a5a5a5;

$color-text-black: #232323;

$background-white: #ffffff;
$background-light: #f9f9fc;
$background-blue: #f2f5f9;
$background-dark-blue: darken(#f2f5f9, 5%);
$color-background-help: #e3ebf7;
$color-border-help: #cadaef;

// FONT
$font-size-paragraph: 14px;
$font-size-title: 26px;
$font-size-title-2: 20px;

// HEADER & FOOTER
:root {
  --header-height: 69px;
  --footer-height: 169px;
}

// RESPONSIVE
$size-small-padding: 15px;
$size-large-width: 1272px;

$responsive-large: 1400px;

// BROWSER COMPATIBILITY

@mixin supportEdge($propertyName, $value) {
  @supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
    #{$propertyName}: $value;
  }
}
