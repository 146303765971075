@import 'variables';

mat-horizontal-stepper.mat-stepper-horizontal {
  background: transparent;
  font-family: 'WorkSans', sans-serif;

  .mat-horizontal-stepper-header-container {
    background: $background-white;

    .mat-horizontal-stepper-header {
      padding: 0 36px;
    }
  }

  .mat-step-header {
    border-bottom: 2px solid transparent;
    transition: 0.3s all;

    &[aria-selected='true'] {
      border-bottom: 2px solid $color-primary;
    }
  }

  .mat-stepper-horizontal-line {
    border: solid $black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    min-width: auto;
    margin: 0;
    flex: initial;
  }
}
