@import 'variables';

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'WorkSans';
  src: url('assets/fonts/WorkSans/WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'WorkSans';
  src: url('assets/fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('assets/fonts/WorkSans/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans';
  src: url('assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/*
 * Material Icons
 * replaces: https://fonts.googleapis.com/icon?family=Material+Icons
 */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/material.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat-icon {
  &.success {
    color: $color-success;
  }

  &.warning {
    color: $color-warning;
  }

  &.error {
    color: $color-error;
  }
}

@import '~ngx-toastr/toastr';

@import 'styles/variables';
@import 'styles/buttons';
@import 'styles/inputs';
@import 'styles/stepper';
@import 'styles/table';
@import 'styles/range';

html,
body {
  font-family: 'WorkSans', sans-serif;
  background: $background-blue;
  margin: 0;
  width: 100%;
  height: 100%;
  color: $color-text-black;
}

h1 {
  font-size: $font-size-title;
  font-weight: 600;
}

h2 {
  font-size: $font-size-title-2;
  font-weight: 600;
}

p,
span {
  font-family: 'OpenSans', sans-serif;
  font-size: $font-size-paragraph;
}

a:hover {
  cursor: pointer;
}

a.link,
a.link:link,
a.link:visited {
  cursor: pointer;
  color: $color-primary-dark;
  text-decoration: none;
}

:focus {
  outline: none;
}

.allow-cr {
  white-space: pre;
}


.campaignRulesView {
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include supportEdge(justify-content, space-around);
    min-height: 330px;
    min-width: 450px;
    background-color: white;
    padding: 30px;
    &-row {
      p:first-child {
        width: 237px;
        min-width: 237px;
      }
      display: flex;
    }
    &-actions {
      margin-top: 5px;
      text-align: center;
    }
  }
}