@import '~nouislider/distribute/nouislider.min.css';
@import 'variables';

nouislider {
  .noUi-horizontal {
    height: 2px;

    .noUi-handle {
      cursor: grab;
      width: 20px;
      height: 20px;
      border: 3px solid transparent;
      border-radius: 50%;
      box-shadow: none;
      top: -10px;
      background-color: $color-primary;

      &::before,
      &::after {
        content: none;
      }
    }

    .noUi-connects {
      height: 2px;
      top: -1px;

      .noUi-connect {
        background-color: $color-primary;
      }
    }

    .noUi-tooltip {
      background: none;
      border: none;
    }
  }
}
